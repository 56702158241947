<template>
  <div>
    <!-- downloadD7D4F945FC0CD792F87B6FDDAC310E93.swf -->
    <!-- pic1C48A09FACD99EA68802B27A5E963BFD.jpg -->
    <img class="img-large rounded" src="">
    <p>
      Summertime means travel, so get on over to the <router-link :to="{ name: 'creation-lab-list', params: {challengeId: '8cd89d63-e72c-46d1-b515-6459acdeb913'} }">Creation Lab</router-link> to overcome the newest creative challenge!
    </p>
    <p>
      Where in LEGO Universe would you like to get away to relax? How about for adventure? Will you enjoy experiencing exotic LEGO Universe cultures or trying new foods?
    </p>
    <p>
      Inspire everyone by making a fun LEGO Universe vacation destination!
    </p>
  </div>
</template>
